import React, { useEffect, useRef, useState } from 'react';
import Textfield from '../../components/Textfield/index';
import styles from './carousel3D.module.css'; // Importing the CSS module
import img from "../../assets/home/carousle3d.png"
import img1 from "../../assets/home/ThreeD/Img1.png"
import img2 from "../../assets/home/ThreeD/Img2.png"
import img3 from "../../assets/home/ThreeD/Img3.png"
import img4 from "../../assets/home/ThreeD/Img4.png"




const Carousel3D = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('count', count, currentIndex,carouselItems.length)
      if(count === carouselItems.length -1)
      {
        setCurrentIndex(0)
        setCount(0)
        // updateCarousel('prev');

      }
      else{
      updateCarousel('prev');
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [count]);
  const carouselItems = [
    {
      id: 1,
      imageUrl: img1,
      title: 'AR/VR Powered Experiential Learning',
      description: 'Learn by doing, not just seeing, with AR/VR powered experiential learning.',
      badge: 'Popular',
    },
    {
      id: 2,
      imageUrl: img2,
      title: "Top Industry Educators on the Panel",
      description: 'Gain expertise from reputed industry experts on our panel. ',
      badge: 'Popular',
    },
    {
      id: 3,
      imageUrl: img3,
      title: 'Self-Paced Learning',
      description: 'Learn at your own pace, anytime, anywhere. ',
      badge: 'Popular',
    },
    {
      id: 4,
      imageUrl: img4,
      title: 'Variable Learning Modules',
      description: 'Learn your way, with flexible learning modules',
      badge: 'Popular',
    }
  ];



  const updateCarousel = (direction) => {
    if (direction === 'prev') {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length);
      setCount((prevState) => prevState + 1)
    } else if (direction === 'next') {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      setCount((prevState) => prevState - 1)
    }
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        {carouselItems.map((item, index, arr) => {
          let className = styles.carouselItem;
          let scale = 0;
          let opacity = 0;
          let translateX = 0;
          let translateZ = 0;
          let transform = '';
          let zIndex = (arr.length - index) * 9;

          if (index === count) {
            console.log('index', index, 'count', count)
            className += ` ${styles.active}`;
            translateX = (count + index) * 150 + (index * 82);
            console.log('translateX', translateX)
            translateZ = (index) * 200;
            transform += `translateX(-${translateX}px) translateZ(-${translateZ}px)`;
            // }
            opacity = 1;
          } else if (index < count) {
            className += ` ${styles.prev}`;
            translateX = (count + index) * 150;
            translateZ = (index) * 200
            transform += `translateX(-${translateX}px) translateZ(-${translateZ}px)`;
            // }
            scale = 0.8 ** (index + count);
            if (scale) {
              transform += ` scale(${scale})`;
            }
            opacity = 0;
          } else if (index > count) {
            className += ` ${styles.next}`;
            translateX = (count + index) * 150;
            translateZ = (index) * 200
            transform += `translateX(-${translateX}px) translateZ(-${translateZ}px)`;
            // }
            scale = 0.8 ** (index + count);
            if (scale) {
              transform += ` scale(${scale})`;
            }
            opacity = 0.8;
          }

          return (
            <div key={item.id} className={className} style={{ transform: `${transform}`, opacity: opacity, zIndex: zIndex }}>
              <div className={styles.card}>
                <img src={item.imageUrl} alt="Card" />
                <div className={styles.cardContent}>
                  <span className={styles.badge}>{item.badge}</span>
                  {/* <h3>{item.title}</h3> */}
                  <Textfield colorType="black" type="h4">
                    {item.title}
                  </Textfield>
                  <Textfield colorType="black" type="h5">
                    {item.description}
                  </Textfield>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.navigation}>
        <button className={styles.prev} onClick={() => updateCarousel('next')} disabled={Boolean(count === 0 )} style={{ transform: 'rotate(-30deg)' }}>←</button>
        {/* <button className={styles.next} onClick={() => updateCarousel('prev')} disabled={Boolean(count === carouselItems.length - 1)} style={{ transform: 'rotate(30deg)' }}>→</button> */}
      </div>
    </div>
  );
};

export default Carousel3D;
